import React from 'react';
import { Box, TextField, MenuItem } from '@mui/material';
import { ContextModalLote } from '../ContextModalLote/ContextModalLote';

const TabLote = () => {
    const { inputValuesRef, handleInputChange, optionsLote, forceUpdate } = ContextModalLote();

    const handleChange = (field, value) => {
        handleInputChange(field, value);
        forceUpdate({}); // Força uma atualização do componente
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <TextField
                select
                label="Ocupação"
                fullWidth
                value={inputValuesRef.current.ocupacao || ''}
                onChange={(e) => handleChange('ocupacao', e.target.value)}
            >
                {optionsLote.ocupacao.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                select
                label="Nível"
                fullWidth
                value={inputValuesRef.current.nivel || ''}
                onChange={(e) => handleChange('nivel', e.target.value)}
            >
                {optionsLote.nivel.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                        {option.label}
                    </MenuItem>

                ))}
            </TextField>

            <TextField
                select
                label="Situação do Lote"
                fullWidth
                value={inputValuesRef.current.situacaoLote || ''}
                onChange={(e) => handleChange('situacaoLote', e.target.value)}
            >
                {optionsLote.situacaoLote.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                        {option.label}
                    </MenuItem>

                ))}
            </TextField>

            <TextField
                select
                label="Muro/Passeio"
                fullWidth
                value={inputValuesRef.current.muroPasseio || ''}
                onChange={(e) => handleChange('muroPasseio', e.target.value)}
            >
                {optionsLote.muroPasseio.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                        {option.label}
                    </MenuItem>

                ))}
            </TextField>
        </Box>
    );
};

export default TabLote;
