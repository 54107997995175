import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Fab,
} from "@mui/material";

import { Api } from "../../services/Api.js";

import ModalRemembramento from "./ModalRemembramento/ModalRemembramento.jsx";
import ModalMesmoLote from "./ModalMesmoLote/ModalMesmoLote.jsx";
// import ModalSemRegistro from "./ModalSemRegistro/ModalSemRegistro.jsx";
// import ModalEndIncopativeis from "./ModalEndIncopativeis/ModalEndIncopativeis.jsx";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./GerenciarAdversidades.css";
import { ContextMap } from "../../ContextMap/ContextMap";
import { ContextAdv } from "./ContextAdv.jsx";

function GerenciarAdversidades() {
  const [filteredData, setFilteredData] = useState([]);
  const [totalizador, setTotalizador] = useState([]);
  const [idAdversidade, setIdAdversidade] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const [selectedAdversidade, setSelectedAdversidade] = useState("");
  const [selectedTipoUsuario, setSelectedTipoUsuario] = useState("");
  const { alteraOpcao, opcao } = useContext(ContextMap);
  const { idAdv, setIdAdv, idAdversidades } = useContext(ContextAdv);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Api.getAdversidades();

        setTotalizador(data.data);
        // Filtrar os dados com base em selectedAdversidade e selectedTipoUsuario
        let filteredData = data.data.adversidades.filter(
          (item) => item.flag === 0
        );

        // console.log(filteredData);
        // let filteredData = data.data.adversidades;

        if (selectedAdversidade) {
          filteredData = filteredData.filter(
            (item) => item.type === selectedAdversidade
          );
        }

        // Função para filtrar com base no primeiro caractere da string "name"
        if (selectedTipoUsuario) {
          filteredData = filteredData.filter(
            (objeto) => objeto.name.charAt(0) === selectedTipoUsuario
          );
        }

        // Você pode aplicar de maneira similar o filtro para selectedTipoUsuario, se necessário
        setFilteredData(filteredData);
      } catch (error) {
        console.log("Deu erro:", error);
      }
    };
    fetchData();
  }, [selectedAdversidade, selectedTipoUsuario]);

  const opcaoClicada = async (opcaoSelecionada) => {
    await alteraOpcao("");
    alteraOpcao(opcaoSelecionada);
  };

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };
  const [showNovasUnidades, setShowNovasUnidades] = useState(false);

  const advSelecionada = async (enviarId) => {
    await idAdversidades();
    idAdversidades(enviarId);
  };

  const toggleNovasUnidades = () => {
    setShowNovasUnidades(!showNovasUnidades);
  };

  const toggle = (adversidades) => {
    const slug = adversidades.slug;
    const id = adversidades.id;
    setIdAdv(id);
    switch (slug) {
      case "R":
        opcaoClicada("remembramento");
        advSelecionada(id);
        break;
      case "End":
        opcaoClicada("advEndereco");
        break;
      // case "End":
      //   opcaoClicada("incompativeis");
      //   break;
      case "I":
        opcaoClicada("adversidades");
        break;

      case "C":
        opcaoClicada("adversidades");
        break;
      case "NovasUnidades":
        toggleNovasUnidades();
        break;
      default:
    }
  };

  function createData(name, numeros) {
    return { name, numeros };
  }
  // R: Remembramento
  // D: Desmembramento
  // L: Lotes que deveriam ser unidades
  // C: Identificação de unidades imobiliárias dentro do mesmo lote
  // XY: Lotes sem registro no cadastro da prefeitura
  // End: Lotes com endereços incompatíveis com o logradouro
  // r: Lotes não encontrados na quadra
  // s/n: Quadras sem registro no cadastro da prefeitura,

  const rows = [
    createData("Remembramento", totalizador.TR),
    createData("Desmembramento", totalizador.TD),
    createData(
      "Quadras sem registro no cadastro da prefeitura",
      totalizador.Tsn
    ),
    createData(
      "Identificação de unidades imobiliárias dentro do mesmo lote",
      totalizador.TC
    ),
    createData("Novos Lotes", totalizador.TEnd),
  ];

  const rowsSecond = [
    createData("Lotes não encontrados na quadra", totalizador.Tr),
    createData("Lotes que deveriam ser unidades", totalizador.TL),
    createData("Lotes sem registro no cadastro da prefeitura", totalizador.TXY),
    createData(
      "Lotes com endereços incompatíveis com o logradouro",
      totalizador.TEnd
    ),
    createData(""),
  ];

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className='GerenciarAdversidades-lista-container'>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <TableContainer sx={{ maxWidth: 700 }} component={Paper}>
              <Table sx={{ maxWidth: 700 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 11, padding: 7 }}>
                      TOTAL DE ADVERSIDADES
                    </TableCell>
                    <TableCell
                      style={{ fontSize: 11, padding: 7 }}
                      align='right'
                    >
                      {totalizador.totalAdversidades}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        style={{ fontSize: 11, padding: 7 }}
                        component='th'
                        scope='row'
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: 11, padding: 7 }}
                        align='right'
                      >
                        {row.numeros}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer sx={{ maxWidth: 700 }} component={Paper}>
              <Table sx={{ maxWidth: 700 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 11, padding: 7 }}>
                      TOTAL DE ADVERSIDADES RESOLVIDAS
                    </TableCell>
                    <TableCell
                      style={{ fontSize: 11, padding: 7 }}
                      align='right'
                    >
                      {totalizador.totalResolvido}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsSecond.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        style={{ fontSize: 11, padding: 7 }}
                        component='th'
                        scope='row'
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: 11, padding: 7 }}
                        align='right'
                      >
                        {row.numeros}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "58vh",
            }}
          >
            <div className='GerenciarAdversidades-form-row'>
              <div className='GerenciarAdversidades-form-group'>
                <FormControl
                  sx={{ width: "100%", backgroundColor: "white" }}
                  size='small'
                >
                  <InputLabel>Adversidades</InputLabel>
                  <Select
                    label='Adversidades'
                    value={selectedAdversidade}
                    onChange={(event) =>
                      setSelectedAdversidade(event.target.value)
                    }
                  >
                    <MenuItem value=''>
                      <em>Todas as adversidades</em>
                    </MenuItem>
                    <MenuItem value='Remembramento'>(R) Remembramento</MenuItem>
                    <MenuItem value='Desmembramento'>
                      (D) Desmembramento
                    </MenuItem>
                    <MenuItem value='Identificação de unidades imobiliarias dentro do mesmo lote'>
                      (C) Identificação de unidades imobiliárias dentro do mesmo
                      lote
                    </MenuItem>
                    <MenuItem value='Lotes que deveriam ser unidades'>
                      (L) Lotes que deveriam ser unidades
                    </MenuItem>
                    <MenuItem value='Lotes sem registro no cadastro da prefeitura'>
                      (]X.Y[) Lotes sem registro no cadastro da prefeitura
                    </MenuItem>
                    <MenuItem value='Lotes com endereços incompatíveis com o logradouro'>
                      (End) Lotes com endereços incopatíveis com o logradouro
                    </MenuItem>
                    <MenuItem value='Lotes não encontrados na quadra'>
                      (r) Lotes não encontrados na quadra
                    </MenuItem>
                    <MenuItem value='Quadras sem registro no cadastro da prefeitura'>
                      (s/n) Quadras sem registro no cadastro da prefeitura
                    </MenuItem>
                    <MenuItem value='Novas Unidades'>
                      (NU) Novas Unidades
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className='GerenciarAdversidades-form-group'>
                <FormControl
                  sx={{ width: "100%", backgroundColor: "white" }}
                  size='small'
                >
                  <InputLabel>Tipo de Usuário</InputLabel>
                  <Select
                    label='Tipo de Usuário'
                    value={selectedTipoUsuario}
                    onChange={(event) =>
                      setSelectedTipoUsuario(event.target.value)
                    }
                  >
                    <MenuItem value=''>Todos os setores</MenuItem>
                    <MenuItem value='1'>Setor 1</MenuItem>
                    <MenuItem value='2'>Setor 2</MenuItem>
                    <MenuItem value='3'>Setor 3</MenuItem>
                    <MenuItem value='4'>Setor 4</MenuItem>
                    <MenuItem value='5'>Setor 5</MenuItem>
                    <MenuItem value='6'>Setor 6</MenuItem>
                    <MenuItem value='7'>Setor 7</MenuItem>
                    <MenuItem value='8'>Setor 8</MenuItem>
                    <MenuItem value='9'>Setor 9</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div style={{ width: "88%", height: "350px" }}>
              <TableContainer component={Paper} sx={{ height: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Identificador
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Tipo de Adversidade
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      .slice(
                        currentPage * rowsPerPage,
                        currentPage * rowsPerPage + rowsPerPage
                      )
                      .map((adversidades, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{ fontSize: "11px", maxWidth: "180px" }}
                          >
                            {adversidades.id}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "11px", maxWidth: "30px" }}
                          >
                            {adversidades.type}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "11px", maxWidth: "30px" }}
                          >
                            <Button
                              style={{ minWidth: "10px" }}
                              onClick={() => toggle(adversidades)}
                              variant='outlined'
                            >
                              <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[rowsPerPage]}
                  component='div'
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={currentPage}
                  onPageChange={handleChangePage}
                />
              </TableContainer>
            </div>
          </div>
        </div>
      </div>

      {/* {showRemembramento && (
        <section style={{ position: "relative" }}>
          <ModalRemembramento
            idAdversidade={idAdversidade}
            onClose={toggleRemembramento}
          />
        </section>
      )} */}
      {/* {showMesmoLote && (
        <section style={{ position: "relative" }}>
          <ModalMesmoLote
            idAdversidade={idAdversidade}
            onClose={toggleMesmoLote}
          />
        </section>
      )}
      {showSemRegistro && (
        <section style={{ position: 'relative' }}>
          <ModalSemRegistro idAdversidade={idAdversidade} onClose={toggleSemRegistro} />
        </section>
      )}
      {showEndIncopativeis && (
        <section style={{ position: 'relative' }}>
          <ModalEndIncopativeis idAdversidade={idAdversidade} onClose={toggleEndIncopativeis} />
        </section>
      )} */}
    </div>
  );
}

export default GerenciarAdversidades;
