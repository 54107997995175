import { Checkbox, FormControlLabel } from "@mui/material";

export const SetoresFiltros = ({ indiceSetores, checkBox, handleCheckboxChange }) => {
  // Cria um array de setores baseado no índice fornecido
  const setoresArray = Array.from({ length: indiceSetores }, (_, index) => index + 1);

  // Ajusta a função para gerar corretamente o rótulo de cada setor
  const numberSetor = (index) => {
    return indiceSetores >= 2 ? `Setor ${index + 1}` : 'Setor';
  };

  return (
    <div>
      <h5 style={{ marginLeft: 5, padding: 5 }}>Camadas</h5>
      <div style={{ padding: 5, marginLeft: 5, display: "flex", flexDirection: "column" }}>
        {setoresArray.map((setor, index) => (
          <FormControlLabel
            key={setor}
            control={
              <Checkbox
                name={numberSetor(index)}
                color="primary"
                checked={checkBox[index]?.checked || false}
                onChange={() => handleCheckboxChange(index)}
              />
            }
            label={numberSetor(index)}
          />
        ))}
      </div>
    </div>
  );
};
