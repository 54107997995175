import React, { useEffect, useState } from 'react';
import { Box, Button, Autocomplete, TextField, Typography } from '@mui/material';
import { Api } from '../../../../services/Api';

export const ManegerAlocacao = ({ dadosQuadra, resetMapQuadra }) => {

    const [agenteSelecionado, setAgenteSelecionado] = useState(null); // Estado para o agente selecionado
    const [agentes, setAgentes] = useState([]);
    const [agenteAtual, setAgenteAtual] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            const response = await Api.getColetores();
            const arrayColetores = response.data.result.map((item) => item.user);
            setAgentes(arrayColetores);
        };
        fetch();
    }, []);
    useEffect(() => {
        const fetch = async () => {
            const bodyRequestQuadra = {
                "quadraId": dadosQuadra.id,
                "quadraCod": dadosQuadra.cod,
                "setorCod": dadosQuadra.setorCod
            }
            const responseInfoQuadra = await Api.getQuadraInfo(bodyRequestQuadra);

            // Supondo que o nome que você deseja buscar seja o valor de responseInfoQuadra.data.nome
            const nomeBuscado = responseInfoQuadra.data;
            setAgenteAtual(nomeBuscado);

        };
        fetch();
    }, []);

    const fetchRequestQuadraAlocada = async () => {
        // Encontrando o agente correspondente
        return { id: agenteAtual.idColetor, quadrasRemove: [dadosQuadra.id] }
    }

    const patchAlocarQuadra = async (dados) => {
        try {
            const response = await Api.getAlocarColetoresQuadra(dados);
            if (response.message === "updated") {
                resetMapQuadra(dadosQuadra.setorCod)
                alert('Ação concluída!')
            } else {
                alert('Erro: Ação não concluída!')
            }
        } catch { alert('Erro: Ação não concluída!') }
    };

    const patchDesalocarQuadra = async () => {
        const dadosRequestColeta = await fetchRequestQuadraAlocada()

        try {
            const response = await Api.getAlocarColetoresQuadra(dadosRequestColeta);
            if (response.message === "updated") {
                resetMapQuadra(dadosQuadra.setorCod)
                alert('Ação concluída!')
            } else {
                alert('Erro: Ação não concluída!')
            }
        } catch { alert('Erro: Ação não concluída!') }
    };

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    p: 2,
                    m: 'auto',
                    overflow: 'hidden',
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'column',
                    justifyContent: 'start',
                    position: 'relative',
                }}
            >
                <div>
                    <h4>Quadra: {dadosQuadra.id}</h4>
                    <Typography gutterBottom sx={{
                        color: 'text.secondary',
                        fontSize: 12,
                        marginBottom: 0
                    }}> Quadra Cod: {dadosQuadra.cod} </Typography>
                    <Typography gutterBottom sx={{
                        color: 'text.secondary',
                        fontSize: 12,
                        marginBottom: 0
                    }}> Setor Cod: {dadosQuadra.setorCod} </Typography>
                    <Typography gutterBottom sx={{
                        color: 'text.secondary',
                        fontSize: 12,
                        marginBottom: 0
                    }}> Agente: {agenteAtual.nome} </Typography>
                </div>

                {sessionStorage.getItem('infoUsuarioRole') !== 'collector' && dadosQuadra.flag === 0 && <Autocomplete
                    disablePortal
                    options={agentes}
                    getOptionLabel={(option) => option.name} // Define qual campo exibe o rótulo no dropdown
                    fullWidth
                    onChange={(event, newValue) => {
                        setAgenteSelecionado(newValue); // Atualiza o estado com o agente selecionado
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Selecione um agente" sx={{ bgcolor: 'background.paper' }} />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                />}
                {sessionStorage.getItem('infoUsuarioRole') !== 'collector' && (
                    <>
                        {dadosQuadra.flag === 0 && (
                            <Box sx={{ position: 'relative', bgcolor: 'background.paper', zIndex: 1 }}>
                                <Button
                                    onClick={() =>
                                        patchAlocarQuadra({
                                            id: agenteSelecionado?.id,
                                            quadrasInsert: [dadosQuadra.id],
                                        })
                                    }
                                    sx={{ paddingX: 2 }}
                                    variant="contained"
                                >
                                    Alocar Quadra
                                </Button>
                            </Box>
                        )}
                        {dadosQuadra.flag === 1 && (
                            <Box sx={{ position: 'relative', bgcolor: 'background.paper', zIndex: 1 }}>
                                <Button
                                    onClick={() => patchDesalocarQuadra()}
                                    sx={{ paddingX: 2 }}
                                    variant="contained"
                                >
                                    Desalocar Quadra
                                </Button>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </>
    );
};
