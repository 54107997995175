import React, { useEffect, useState, useRef } from 'react';
import { Modal, Box, Button, IconButton, Tab, Tabs } from '@mui/material';
import TabLote from './Tabs/TabLote';
import TabUnidadeImobiliaria from './Tabs/TabUnidadeImobiliaria';
import TabMapa from './Tabs/TabMapa';
import { Close } from '@material-ui/icons';
import { Api } from '../../../../services/Api';
import { TabAreaCoberta } from './Tabs/TabAreaCoberta';
import { ContextModalLote } from './ContextModalLote/ContextModalLote';
import { InputProvider } from './ContextModalLote/ContextModalLote';

export const ModalLote = ({ open, onClose, returnLoteColeta }) => {
    const [legenda, setLegenda] = useState([]);
    const [storage, setStorage] = useState(null);
    const [storageUnidades, setStorageUnidades] = useState([]);
    const [storageApiLote, setStorageApiLote] = useState(null);
    const [value, setValue] = useState(0);
    const [valuePagination, setValuePagination] = useState(0);
    const { getInputs, resetInputValues, setLoteData, } = ContextModalLote();
    const coberturasDataRef = useRef([]);
    const unidadesDataRef = useRef([]);
    const [coberturasData, setCoberturasData] = useState([]);

    const requestLote = async () => {
        try {
            if (returnLoteColeta) {
                const objetoLote = [{ coordenadas: returnLoteColeta.ID.geom, id: {} }];
                const responseLoteForId = await Api.getLoteForId(returnLoteColeta.ID.id);
                const dadosLote = responseLoteForId.data
                console.log(dadosLote);
                const unidades = dadosLote.unidades.map((dado) => {
                    return {
                        id: dado.unidadeImobiliaria.id || 0,
                        areaEdicula: Number(dado.unidadeImobiliaria.areaEdicula) || 0,
                        areaConstrucao: Number(dado.unidadeImobiliaria.areaConstrucao) || 0,
                        conservacao: dado.unidadeImobiliaria.conservacao || null, // valor padrão
                        estrutura: dado.unidadeImobiliaria.estrutura, // Não há esse dado no objeto original, valor padrão
                        cobertura: dado.unidadeImobiliaria.cobertura || null,  // Não há esse dado no objeto original, valor padrão
                        instSanitaria: dado.unidadeImobiliaria.instSanitaria1 || null,  // Não há esse dado no objeto original, valor padrão
                        instEletrica: dado.unidadeImobiliaria.instEletrica || null,  // Não há esse dado no objeto original, valor padrão
                        categoria: dado.unidadeImobiliaria.categoria || null,  // Não há esse dado no objeto original, valor padrão
                        revestimento: dado.unidadeImobiliaria.revestimento || null,  // Não há esse dado no objeto original, valor padrão
                        fachada: dado.unidadeImobiliaria.fachada || null, // valor padrão
                        situacao: dado.unidadeImobiliaria.situacao || null, // valor padrão
                        forro: dado.unidadeImobiliaria.forro || null, // valor padrão
                        parede: dado.unidadeImobiliaria.parede || null, // valor padrão
                        esquadrias: dado.unidadeImobiliaria.esquadrias || null, // valor padrão
                        acesso: dado.unidadeImobiliaria.acesso || null, // valor padrão
                    };
                });

                const obj3 = {
                    id: dadosLote.lote.id,
                    enderecoId: dadosLote.lote.enderecoId || dadosLote.unidades[0].endereco.id,
                    lote: {
                        id: dadosLote.lote.id || 0,
                        areaTerreno: dadosLote.lote.areaTerreno || 0,
                        areaTerrenoIsento: dadosLote.lote.areaTerrenoIsento || 0,
                        areaConstrucao: dadosLote.lote.areaConstrucao || 0,
                        fracaoIdeal: dadosLote.lote.fracaoIdealInfo || 0,
                        topologia: dadosLote.lote.topologia || null,
                        pedologia: dadosLote.lote.pedologia || null,
                        nivelacao: dadosLote.lote.nivelacao || null,
                        tipo: dadosLote.lote.tipo || null,
                        segmento: dadosLote.lote.segmento || null,
                        lado: dadosLote.lote.lado || null,
                        utilizacao: dadosLote.lote.utilizacao || null,
                        patrimonio: dadosLote.lote.patrimonio || null,
                        finalidade: dadosLote.lote.finalidade || null,
                        frentes: dadosLote.lote.frentes || null,
                        arvore: dadosLote.lote.arvore || null,
                        formato: dadosLote.lote.formato || null,
                        predial: dadosLote.lote.predial === "s" ? "Sim" : "Não",
                        profundidade: dadosLote.lote.profundidade || 0,
                        vago: dadosLote.lote.vago || null,
                        conservacao: dadosLote.lote.conservacao || null
                    },
                    unidades: unidades,
                    pois: [
                        {
                            id: 0,
                            unidadeId: 0,
                            tipo: "",
                            nome: ""
                        }
                    ]
                };

                setLoteData(obj3); // Adicione esta linha para armazenar os dados do obj3
                setStorageApiLote(responseLoteForId);
                setStorage(objetoLote);
                const arrayUnidades = responseLoteForId.data.unidades.map((item) => item.unidadeImobiliaria);
                setStorageUnidades(arrayUnidades);
            }

        } catch { console.log('Erro na requsição de Lote por Id') }

    };

    useEffect(() => {
        if (returnLoteColeta) { requestLote() }
    }, [returnLoteColeta]); // Executa apenas quando returnLoteColeta estiver disponível

    const getCoberturas = (item) => { setLegenda(item[0]) };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
                style={{
                    height: '91%', // Defina a altura fixa aqui
                    overflowY: 'auto', // Habilita a rolagem vertical
                    flexGrow: 1,
                    padding: 10,
                    paddingLeft: 0,
                    paddingBottom: 0,
                    marginTop: 0
                }}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        resetInputValues();
        onClose();
    };
    const handleSave = async () => {
        handleChange(1);
        if (getInputs()) {
            await new Promise(resolve => setTimeout(resolve, 0));

            const unidadesAtualizadas = storageUnidades.map(item => ({
                ...item,
                ...unidadesDataRef.current.find(u => u.id === item.id)
            }));

            const pois = unidadesDataRef.current
                .filter(u => u.tipoInteresse && u.nomeInteresse)
                .map(u => ({
                    id: u.id,
                    unidadeId: u.id,
                    tipo: u.tipoInteresse,
                    nome: u.nomeInteresse
                }));

            const dadosParaSalvar = {
                ...getInputs(),
                coberturas: coberturasData,
                unidades: unidadesAtualizadas,
                pois: pois
            };

            try {
                const response = await Api.getSaveColeta(dadosParaSalvar);
                handleClose();
                console.log(response);
                if (response.status < 300)
                    alert('Sucesso ao salvar dados!')
                else {
                    alert('Erros ao salvar dados!')
                }
            } catch (erro) {
                alert('Erros ao salvar dados!')
                console.error('Erro ao salvar a coleta:', erro);
            }
        }
    };

    const handleCoberturasUpdate = (newCoberturas) => {
        setCoberturasData(newCoberturas);
    };

    const handleUnidadesUpdate = (newUnidades) => {
        unidadesDataRef.current = newUnidades;
    };

    return (
        <InputProvider>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        width: '70rem',
                        height: '80vh',
                        bgcolor: 'background.paper',
                        py: 2,
                        m: 'auto',
                        mt: 10,
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        position: 'relative' // Necessário para posicionar o botão de fechar
                    }}
                >
                    {/* Ícone de fechar no canto superior direito */}
                    <div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
                        <div style={{ display: 'flex', width: '100%', height: '100%', paddingInline: 20 }}>
                            {storage && <TabMapa geometriaLoteColeta={storage} responseLoteForId={storageApiLote} getCoberturas={getCoberturas} />}
                        </div>
                        <div style={{ width: '100%', height: '100%' }}>
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 5,
                                    color: '#1769aa',
                                    zIndex: 2
                                }}
                            >
                                <Close />
                            </IconButton>
                            <div style={{ height: '60vh' }}>
                                <Tabs value={value} onChange={handleChange} >
                                    <Tab label="Lote" {...a11yProps(0)} />
                                    <Tab label="Unidade Imobiliária" {...a11yProps(1)} />
                                    {legenda.length > 0 && < Tab label="Coberturas" {...a11yProps(2)} />}
                                </Tabs>



                                <TabPanel value={value} index={0}>
                                    <TabLote />
                                </TabPanel>



                                <TabPanel value={value} index={1}>
                                    <TabUnidadeImobiliaria
                                        unidades={storageUnidades}
                                        unidadesDataAtual={unidadesDataRef.current}
                                        onUpdate={handleUnidadesUpdate}
                                    />
                                </TabPanel>



                                <TabPanel value={value} index={2}>
                                    <TabAreaCoberta
                                        coberturas={legenda}
                                        onUpdate={handleCoberturasUpdate}
                                        coberturasExistentes={coberturasData}
                                    />
                                </TabPanel>
                            </div>
                            <Button sx={{ position: 'absolute', width: 35, paddingX: 6, bottom: 10, right: 5 }} onClick={handleSave} variant='contained'>Salvar</Button>
                        </div>
                    </div>

                </Box>

            </Modal>
        </InputProvider>
    );
};
