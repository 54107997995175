import { Divider } from "@mui/material"


export const Legenda = ({ checkboxState, handleCheckboxChange }) => {

    return (

        <div>
            {/* <>
                <h5 style={{ marginLeft: 5, padding: 5 }}>QUADRAS</h5>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                    <div style={{ background: '#e57373', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Não Coletados</h5>
                </div>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                    <div style={{ background: 'rgb(19,100,181)', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Incompletos</h5>
                </div>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                    <div style={{ background: 'rgba(114,178,137)', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Coletados</h5>
                </div>
                <Divider style={{ marginBlock: 20, marginInline: 10 }} />
            </> */}
            <>
                {/* <h5 style={{ marginLeft: 5, padding: 5 }}>LOTES</h5> */}

                <div style={{ display: 'flex', padding: 5 }}>
                    <div style={{ background: '#e57373', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Não Coletados</h5>
                </div>

                <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                    <div style={{ background: 'rgb(19,100,181)', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Coletados</h5>
                </div>

                {/* <div style={{ display: 'flex', padding: 5, paddingTop: 2 }}>
                    <div style={{ background: '#e1f273', height: '20px', width: '20px', margin: '5px' }} ></div>
                    <h5 style={{ margin: '5px', marginLeft: '0px', width: 'auto' }}>Incompletos</h5>
                </div> */}
                <Divider style={{ marginBlock: 10, marginInline: 10 }} />
            </>
        </div>
    )
}