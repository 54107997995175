import React, { useContext } from "react";
import MapRender from "../MapRender/MapRender";
import { ContextMap } from "../../ContextMap/ContextMap";
import FloatingButton from "../FloatingButton/FloatingButton";
import AlterarProprietario from "../AlterarProprietario/AlterarProprietario";
import AlterarEixo from "../AlterarEixo/AlterarEixo";
import AlterarImovel from "../AlterarImovel/AlterarImovel";
import AlterarLogradouro from "../AlterarLogradouro/AlterarLogradouro";
import GerenciarAdversidades from "../GerenciarAdversidades/GerenciarAdversidades";
import GerenciarUsuarios from "../GerenciaUsuarios/GerenciarUsuarios";
import AlterarPerfil from "../AlterarPerfil/AlterarPerfil";
import ImprimirBic from "../ImprimirBic/ImprimirBic";
import GerenciarColetas from "../GerenciarColetas/GerenciarColetas";
import ExportarDados from "../ExportarDados/ExportarDados";
import ModalRemembramento from "../GerenciarAdversidades/ModalRemembramento/ModalRemembramento";
import ModalEndIncopativeis from "../GerenciarAdversidades/ModalEndIncopativeis/ModalEndIncopativeis";
// import ModalSemRegistro from "../GerenciarAdversidades/ModalSemRegistro/ModalSemRegistro";
import ModalMesmoLote from "../GerenciarAdversidades/ModalMesmoLote/ModalMesmoLote";
import Inconsistencias from "../Inconsistencias/Inconsistencias";

import { ContextAdvProvider } from "../GerenciarAdversidades/ContextAdv";
import AdvEndereco from "../GerenciarAdversidades/AdversidadeEndereco/AdvEndereco";
import { Adversidades } from "../GerenciarAdversidades/Adversidades";

function Conteudo(estadoLogin) {
  const { opcao } = useContext(ContextMap);
  let { idAdv } = useContext(ContextMap);

  function renderizarCampoDeEntrada() {
    let opcaoControle = estadoLogin.estadoLogin === "" ? "home" : opcao;
    switch (opcaoControle) {
      case "home":
        return (
          <div>
            <MapRender />
            <FloatingButton />
          </div>
        );
      case "alterarImovel":
        return (
          <div>
            <AlterarImovel />
          </div>
        );
      case "alterarProprietario":
        return (
          // <h1>alterarProprietario</h1>
          <div>
            <AlterarProprietario />
          </div>
        );
      case "alterarEixo":
        return (
          // <h1>alterarEixo</h1>
          <div>
            <AlterarEixo />
          </div>
        );
      case "alterarLogradouro":
        return (
          // <h1>alterarLogradouro</h1>
          <div>
            <AlterarLogradouro />
          </div>
        );
      case "gerenciarAdversidades":
        return (
          // <h1>gerenciarAdversidades</h1>
          <div>
            <GerenciarAdversidades />
          </div>
        );
      case "gerenciarUsuarios":
        return (
          <div>
            <GerenciarUsuarios />
          </div>
        );
      case "alterarPerfil":
        return (
          <div>
            <AlterarPerfil />
          </div>
        );

      case "imprimirBic":
        return (
          <div>
            <ImprimirBic />
          </div>
        );
      case "gerenciarColetas":
        return (
          <div>
            <GerenciarColetas />
          </div>
        );
      case "exportarDados":
        return (
          <div>
            <ExportarDados />
          </div>
        );
      case "remembramento":
        return (
          <div>
            <ModalRemembramento idAdversidade={idAdv} />
          </div>
        );
      case "incompativeis":
        return (
          <div>
            <ModalEndIncopativeis idAdversidade={idAdv} />
          </div>
        );
      // case "semRegistro":
      //   return (
      //     <div>
      //       <ModalSemRegistro idAdversidade={idAdv} />
      //     </div>
      //   );
      case "mesmoLote":
        return (
          <div>
            <ModalMesmoLote idAdversidade={idAdv} />
          </div>
        );
      case "inconsistencias":
        return (
          <div>
            <Inconsistencias />
          </div>
        );
      case "advEndereco":
        return (
          <div style={{ width: "100%", height: "100%" }}>
            <AdvEndereco />
          </div>
        );
      case "adversidades":
        return <Adversidades />;

      default:
        return null;
    }
  }
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "91vh",
        margin: "0 auto",
        backgroundColor: " rgb(245, 248, 255)",
        zIndex: "4",
        overflowY: "auto", // adiciona essa propriedade
      }}
    >
      <ContextAdvProvider>{renderizarCampoDeEntrada()}</ContextAdvProvider>
    </div>
  );
}

export default Conteudo;
