import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";

import "../Sidebar/Sidebar.css";
import LogoPrefeitura from "../../assets/img/logo_saolourenco.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap,
  faHouseChimney,
  faUser,
  faRoad,
  faTriangleExclamation,
  faCaretRight,
  faUsers,
  faFile,
  faGlobe,
  faDownload,
  faEarthAmericas,
  faFileCircleExclamation
} from "@fortawesome/free-solid-svg-icons";

import { ContextMap } from "../../ContextMap/ContextMap";
function Sidebar({ showSidebar }) {
  const [showAlteracoesContainer, setShowAlteracoesContainer] = useState(false);
  const [showAdversidadesContainer, setShowAdversidadesContainer] = useState(false);
  const [showUsuarioContainer, setShowUsuarioContainer] = useState(false);
  const [showRelatoriosContainer, setShowRelatoriosContainer] = useState(false);
  const [coletor, setColetor] = useState(false);
  const { alteraOpcao, opcao } = useContext(ContextMap);

  useEffect(() => {
    logTipoUsuario();
  }, []);

  const toggleAlteracoesContainer = () => {
    setShowAlteracoesContainer(!showAlteracoesContainer);
    setShowAdversidadesContainer(false);
    setShowUsuarioContainer(false);
    setShowRelatoriosContainer(false);
  };
  const toggleAdversidadesContainer = () => {
    setShowAlteracoesContainer(false);
    setShowAdversidadesContainer(!showAdversidadesContainer);
    setShowUsuarioContainer(false);
    setShowRelatoriosContainer(false);
  };
  const toggleUsuarioContainer = () => {
    setShowAlteracoesContainer(false);
    setShowAdversidadesContainer(false);
    setShowUsuarioContainer(!showUsuarioContainer);
    setShowRelatoriosContainer(false);
  };
  const toggleRelatoriosContainer = () => {
    setShowAlteracoesContainer(false);
    setShowAdversidadesContainer(false);
    setShowUsuarioContainer(false);
    setShowRelatoriosContainer(!showRelatoriosContainer);
  };

  const opcaoClicada = async (opcaoSelecionada) => {
    await alteraOpcao("");
    alteraOpcao(opcaoSelecionada);
  };

  const logTipoUsuario = () => {
    const token = sessionStorage.getItem("infoUsuarioToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const usuarioInfo = JSON.parse(decodedToken.sub);
        console.log("Informações do usuário:", usuarioInfo);
        console.log("Função:", usuarioInfo.role);
        
        // Adicionando a role ao sessionStorage
        sessionStorage.setItem("infoUsuarioRole", usuarioInfo.role);
        sessionStorage.setItem("infoUsuarioId", usuarioInfo.id)
        
        if (usuarioInfo.role === 'collector')
          setColetor(true)
      } catch (error) {
        console.error("Erro ao decodificar o token ou analisar JSON:", error);
      }
    } else {
      console.log("Token não encontrado no sessionStorage");
    }
  };

  return (
    <div className={`sidebar ${showSidebar ? "show" : ""}`}>
      <div className="container">
        {/* <img src={LogoPrefeitura} alt="logo" className="logo-sidebar"></img> */}
        <ul>
          <li
            className={`li-principal ${opcao === "home" ? "active" : ""}`}
            onClick={() => opcaoClicada("home")}
          >
            <FontAwesomeIcon
              icon={faEarthAmericas}
              style={{ marginRight: 3 }}
            />{" "}
            Home
          </li>
          {!coletor && <>
            <li className="li-bold" onClick={toggleAlteracoesContainer}>
              Alterações{" "}
              <FontAwesomeIcon
                icon={faCaretRight}
                className={`icone ${showAlteracoesContainer ? "rotacionar" : ""}`}
              />
            </li>
            {showAlteracoesContainer && (
              <div className="text-container">
                <ul>
                  <li
                    className={opcao === "alterarImovel" ? "active" : ""}
                    onClick={() => opcaoClicada("alterarImovel")}
                  >
                    <FontAwesomeIcon
                      icon={faHouseChimney}
                      style={{ marginRight: 3 }}
                    />{" "}
                    Alterar imóvel
                  </li>
                  <li
                    className={opcao === "alterarProprietario" ? "active" : ""}
                    onClick={() => opcaoClicada("alterarProprietario")}
                  >
                    <FontAwesomeIcon icon={faUser} style={{ marginRight: 3 }} />{" "}
                    Alterar proprietário
                  </li>
                  <li
                    className={opcao === "alterarEixo" ? "active" : ""}
                    onClick={() => opcaoClicada("alterarEixo")}
                  >
                    <FontAwesomeIcon icon={faRoad} style={{ marginRight: 3 }} />{" "}
                    Alterar eixo
                  </li>
                  <li
                    className={opcao === "alterarLogradouro" ? "active" : ""}
                    onClick={() => opcaoClicada("alterarLogradouro")}
                  >
                    <FontAwesomeIcon icon={faMap} style={{ marginRight: 3 }} />{" "}
                    Alterar logradouro
                  </li>
                </ul>
              </div>
            )}
            <li className="li-bold" onClick={toggleAdversidadesContainer}>
              Adversidades{" "}
              <FontAwesomeIcon
                icon={faCaretRight}
                className={`icone ${showAdversidadesContainer ? "rotacionar" : ""
                  }`}
              />
            </li>
            {showAdversidadesContainer && (
              <div className="text-container">
                <ul>
                  <li
                    className={opcao === "gerenciarAdversidades" ? "active" : ""}
                    onClick={() => opcaoClicada("gerenciarAdversidades")}
                  >
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      style={{ marginRight: 3 }}
                    />{" "}
                    Gerenciar adversidades
                  </li>
                </ul>
              </div>
            )}
            <li className="li-bold" onClick={toggleUsuarioContainer}>
              Usuário{" "}
              <FontAwesomeIcon
                icon={faCaretRight}
                className={`icone ${showUsuarioContainer ? "rotacionar" : ""}`}
              />
            </li>
            {showUsuarioContainer && (
              <div className="text-container">
                <ul>
                  <li
                    className={opcao === "gerenciarUsuarios" ? "active" : ""}
                    onClick={() => opcaoClicada("gerenciarUsuarios")}
                  >
                    <FontAwesomeIcon icon={faUsers} style={{ marginRight: 3 }} />{" "}
                    Gerenciar usuários
                  </li>
                  <li
                    className={opcao === "alterarPerfil" ? "active" : ""}
                    onClick={() => opcaoClicada("alterarPerfil")}
                  >
                    <FontAwesomeIcon icon={faUser} style={{ marginRight: 3 }} />{" "}
                    Alterar perfil
                  </li>
                </ul>
              </div>
            )}
            <li
              className={`li-principal ${opcao === "inconsistencias" ? "active" : ""
                }`}
              onClick={() => opcaoClicada("inconsistencias")}
            >
              <FontAwesomeIcon icon={faFileCircleExclamation} style={{ marginRight: 3 }} />{" "}
              Inconsistências
            </li>
            <li className="li-bold" onClick={toggleRelatoriosContainer}>
              Relatórios{" "}
              <FontAwesomeIcon
                icon={faCaretRight}
                className={`icone ${showRelatoriosContainer ? "rotacionar" : ""}`}
              />
            </li>
            {showRelatoriosContainer && (
              <div className="text-container">
                <ul>
                  <li
                    className={opcao === "imprimirBic" ? "active" : ""}
                    onClick={() => opcaoClicada("imprimirBic")}
                  >
                    <FontAwesomeIcon icon={faFile} style={{ marginRight: 3 }} />{" "}
                    Imprimir BIC
                  </li>
                </ul>
              </div>
            )}
          </>}
          <li
            className={`li-principal ${opcao === "gerenciarColetas" ? "active" : ""
              }`}
            onClick={() => opcaoClicada("gerenciarColetas")}
          >
            <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 3 }} />{" "}
            Gerenciar coletas
          </li>
          {!coletor && <li
            className={`li-principal ${opcao === "exportarDados" ? "active" : ""
              }`}
            onClick={() => opcaoClicada("exportarDados")}
          >
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: 3 }} />{" "}
            Exportar dados
          </li>}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
