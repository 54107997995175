import { IAdversidade, IPontoCentral, IGeom } from "./types";

const url = process.env.REACT_APP_API;

type AdversidadesGlobalAPIResponse = {
  status: number;
  message: string;
  data: {
    adversidadeId: number;
    adversidade: IAdversidade;
    logradouros: {
      id: number;
      nome: string;
    }[];
    pontoCentral: IPontoCentral[];
    testadas: {
      loteid: number;
      logradouroid: number;
      logradouronome: string;
      id: number;
      geom: Omit<IGeom, "coordinates"> & { coordinates: number[][][] };
      length: number;
    }[];
    testadasIds: number[];
    lotesV: any[];
    coberturas: {
      id: number;
      loteId: number;
      tipo: string;
      geom: Omit<IGeom, "coordinates"> & { coordinates: number[][][] };
    }[];
    benfeitorias: any[];
    lotes: any[];
  };
};

export type AdversidadesGlobalResponse = {
  adversidadeId: number;
  adversidade: IAdversidade;
  logradouros: {
    id: number;
    nome: string;
  }[];
  pontoCentral: IPontoCentral[];
  testadas: {
    loteid: number;
    logradouroid: number;
    logradouronome: string;
    id: number;
    geom: Omit<IGeom, "coordinates"> & { coordinates: number[][][] };
    length: number;
  }[];
  testadasIds: number[];
  lotesV: any[];
  coberturas: {
    id: number;
    loteId: number;
    tipo: string;
    geom: Omit<IGeom, "coordinates"> & { coordinates: number[][][] };
  }[];
  benfeitorias: any[];
  lotes: any[];
};

export async function getAdversidadesGlobalService(
  id: number
): Promise<AdversidadesGlobalResponse> {
  try {
    const response = await fetch(url + "/adversidades/global/" + id, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        "ngrok-skip-browser-warning": "any",
      },
    });
    const data: AdversidadesGlobalAPIResponse = await response.json();
    return data.data;
  } catch (error) {
    throw new Error("Não foi possível obter adversidades");
  }
}
