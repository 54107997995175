import React, { useState, useEffect, useContext } from "react";
import { Text, Container, Content } from "./styles";
import { Loading } from "../../common";

import { ColunaMapa } from "../AdversidadeMesmoLote/Components/ColunaMapa";
import { ColunaDados } from "../AdversidadeMesmoLote/Components/ColunaDados";

import { ContextAdv } from "../ContextAdv";
import { ContextProviderInput } from "../AdversidadeMesmoLote/Context/ContextInputs";
import { MapProviderAdv } from "../AdversidadeMesmoLote/Context/FuncoesMapa";

import {
  AdversidadesGlobalResponse,
  getAdversidadesGlobalService,
} from "../../../services/getAdversidadesGlobalService";

import { InitialTable } from "./Components/InitialTable";
import { postSaveAdversidadesGlobalService } from "../../../services/postSaveAdversidadesGlobalService";

enum VIEW_ADVERSIDADES {
  I = "I",
  C = "C",
  R = "Remembramento",
  End = "Endereco",
  Erro = "Erro",
}

export function Adversidades() {
  const [erroText, setErroText] = useState<string>("");
  const [viewAdversidades, setViewAdversidades] =
    useState<VIEW_ADVERSIDADES | null>(null);

  const [adversidade, setAdversidade] = useState<
    AdversidadesGlobalResponse | undefined
  >(undefined);

  const { idAdv } = useContext(ContextAdv);

  function TypeAdversidadeMatch(
    apiResponse: AdversidadesGlobalResponse
  ): VIEW_ADVERSIDADES {
    const enumValue = Object.values(VIEW_ADVERSIDADES).find(function (value) {
      return value === apiResponse.adversidade.tipo;
    });
    if (!enumValue) throw new Error("Tipo de adversidade desconhecido");
    return enumValue;
  }

  async function fetchAdversidades() {
    try {
      const response = await getAdversidadesGlobalService(idAdv);
      setAdversidade(response);
      setViewAdversidades(TypeAdversidadeMatch(response));
    } catch (error: any) {
      setErroText(error.message || "Erro desconhecido");
      setViewAdversidades(VIEW_ADVERSIDADES.Erro);
    }
  }

  useEffect(() => {
    fetchAdversidades();
  }, [idAdv]);

  function handleSearchLote() {
    window.alert("Buscando lote...");
  }

  async function saveAdversidade(adversidadeToSave: any) {
    window.alert("Salvando...");
    console.log(adversidadeToSave);
    const data = {
      adversidadeToSave,
    };
    // try {
    //   //@ts-ignore
    //   await postSaveAdversidadesGlobalService({ data: data });
    // } catch (error: any) {
    //   window.alert(error.message);
    // }
  }

  return (
    <ContextProviderInput>
      <MapProviderAdv>
        <Container>
          <Content>
            {!viewAdversidades && <Loading />}

            {viewAdversidades === VIEW_ADVERSIDADES.Erro && (
              <Text>Erro ao carregar adversidade</Text>
            )}

            {adversidade && viewAdversidades === VIEW_ADVERSIDADES.I && (
              <>
                <ColunaMapa responseAdversidade={adversidade} />
                <InitialTable
                  title='LOTE SEM REGISTRO NO CADASTRO DA PREFEITURA'
                  handleSearchLote={handleSearchLote}
                  adversidade={adversidade}
                  saveAdversidade={saveAdversidade}
                />
              </>
            )}
            {adversidade && viewAdversidades === VIEW_ADVERSIDADES.C && (
              <>
                <ColunaMapa responseAdversidade={adversidade} />
                <InitialTable
                  title='IDENTIFICAÇÃO DE UNIDADE IMOBILIÁRIA DENTRO DO MESMO LOTE'
                  handleSearchLote={handleSearchLote}
                  adversidade={adversidade}
                  numCodigo={42}
                  saveAdversidade={saveAdversidade}
                />
              </>
            )}
          </Content>
        </Container>
      </MapProviderAdv>
    </ContextProviderInput>
  );
}
