import React from "react";
import {
  Container,
  Card,
  Title,
  Divider,
  ButtonContainer,
  Button,
  Box,
  RowContainer,
} from "./styles";

import {
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";

import {
  InputComponent,
  SelectComponent,
} from "../../../AdversidadeMesmoLote/Components/Inputs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

import { AdversidadesGlobalResponse } from "../../../../../services/getAdversidadesGlobalService";
import { InputContext } from "../../../AdversidadeMesmoLote/Context/ContextInputs";
import { InputDados } from "../../../AdversidadeMesmoLote/Components/InputDados";
import { MapContextAdv } from "../../../AdversidadeMesmoLote/Context/FuncoesMapa";

type Props = {
  title: string;
  numCodigo?: number;
  adversidade: AdversidadesGlobalResponse;
  handleSearchLote: () => void;
  saveAdversidade: (adversidadeToSave: any) => void;
};

export function InitialTable({
  title,
  numCodigo,
  adversidade,
  handleSearchLote,
  saveAdversidade,
}: Props) {
  const [formData, setFormData] = React.useState({
    numCodigo: numCodigo ? numCodigo : "",
    distrito: "",
    setor: "",
    quadra: "",
    loteCod: "",
    valueRadioGroupTestada: "",
    valueSelectTestada: "",
    numUnidades: "",
    endereco: {
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      apartamento: "",
      loteamento: "",
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setFormData((prevData) => {
      // Verifica se o campo pertence ao objeto 'endereco'
      if (id.startsWith("endereco.")) {
        const field = id.split(".")[1]; // Extrai a chave, por exemplo, 'numero'

        return {
          ...prevData,
          endereco: {
            ...prevData.endereco,
            [field]: value, // Atualiza o campo específico dentro de 'endereco'
          },
        };
      }

      // Caso contrário, atualiza o estado normalmente
      return {
        ...prevData,
        [id]: value,
      };
    });
  };

  const handleRadioChange = (value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      valueRadioGroupTestada: value,
    }));
  };

  const handleSelectChange = (id: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const [edit, setEdit] = React.useState(numCodigo ? true : false);

  const {
    toggleContinue,
    setNumUnidades,
    numUnidades,
    ConsultaLote,

    setTestadasIds,
    handleSubmitData,
  } = React.useContext(InputContext);

  async function handleToggleLote() {
    // handleSearchLote();

    try {
      const resp = await ConsultaLote(formData.numCodigo);
      if (resp !== 0) {
        setEdit(true);

        setFormData((prevData) => ({
          ...prevData,
          distrito: resp.data.distritoCod,
          setor: resp.data.setorCod,
          quadra: resp.data.quadraCod,
          loteCod: resp.data.loteCod,
          endereco: {
            logradouro: resp.data.endereco.logradouro,
            bairro: resp.data.endereco.bairro,
            numero: resp.data.endereco.numero,
            complemento: resp.data.endereco.complemento,
            apartamento: resp.data.endereco.apartamento,
            loteamento: resp.data.endereco.loteamento,
          },
        }));
        const logradouroApi = adversidade.testadas.map((logNome) => {
          return logNome;
        });

        setTestadas(logradouroApi);
        setTestadasIds(adversidade.testadasIds);
        setCoberturas(adversidade.coberturas);
      }
    } catch (error) {
      console.error("Erro ao consultar lote:", error);
    }
  }

  const { setTestadas, setCoberturas } = React.useContext(MapContextAdv);

  function handleContinue() {
    toggleContinue();
  }

  const colorsTestadas = [
    "rgb(23, 126, 137)",
    "rgb(219, 58, 52)",
    "rgb(255, 200, 87)",
    "rgb(8, 76, 97)",
    "rgb(84, 13, 110)",
    "rgb(238, 66, 102)",
    "rgb(14, 173, 105)",
    "rgb(228, 193, 249)",
    "rgb(152, 82, 119)",
    "rgb(0, 20, 39)",
  ];

  function handleSave() {
    const response = handleSubmitData(adversidade.adversidadeId);
    saveAdversidade(response);
  }

  return (
    <Container>
      <Card>
        <Title>{title}</Title>

        <Divider />

        {/* InputCod */}
        <form style={{ width: "100%" }}>
          <Box>
            <div style={{ flex: 1 }}>
              <h5>Dados do Lote:</h5>

              <RowContainer>
                <InputComponent
                  label='Id/Reduzido'
                  id='numCodigo'
                  value={formData.numCodigo}
                  onChange={handleInputChange}
                  required={edit}
                  readOnly={edit}
                />
                <InputComponent
                  label='Distrito'
                  id='distrito'
                  value={formData.distrito}
                  required
                  readOnly
                  onChange={handleInputChange}
                />
                <InputComponent
                  label='Setor'
                  id='setor'
                  value={formData.setor}
                  required
                  readOnly
                  onChange={handleInputChange}
                />
                <InputComponent
                  label='Quadra'
                  id='quadra'
                  value={formData.quadra}
                  required
                  readOnly
                  onChange={handleInputChange}
                />
                <InputComponent
                  label='Lote'
                  id='loteCod'
                  value={formData.loteCod}
                  required
                  readOnly
                  onChange={handleInputChange}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 10,
                    width: "100%",
                    height: 60,
                  }}
                >
                  <label style={{ display: "block", marginBottom: 5 }}>
                    Consulta Lote:
                  </label>
                  <Button
                    sx={{
                      height: "28px",
                      padding: "5px",
                      minWidth: "100px",
                      verticalAlign: "middle",
                    }}
                    onClick={edit ? () => setEdit(false) : handleToggleLote}
                  >
                    {edit ? "Trocar" : "Buscar"}
                  </Button>
                </div>
              </RowContainer>

              <FormControl>
                {adversidade.testadas.length > 0 && (
                  <FormLabel
                    id='demo-controlled-radio-buttons-group'
                    style={{ marginLeft: "10px" }}
                  >
                    Testada Principal:
                  </FormLabel>
                )}
                <div style={{ display: "flex", gap: 5, marginLeft: 8 }}>
                  {adversidade.testadas.map((testada, index) => {
                    return (
                      <RadioGroup
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          width: "100%",
                        }}
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={formData.valueRadioGroupTestada}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "start",
                            width: "100%",
                          }}
                        >
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <FormControlLabel
                              value={`${testada.id}${index}`}
                              control={<Radio />}
                              label={``}
                              onClick={() =>
                                handleRadioChange(`${testada.id}${index}`)
                              }
                            />
                            <div
                              style={{
                                background: colorsTestadas[index],
                                height: "20px",
                                width: "20px",
                                marginLeft: "-15px",
                              }}
                            />
                            <label style={{ marginLeft: "8px" }}>
                              {testada.logradouronome}
                            </label>
                          </div>
                        </div>
                      </RadioGroup>
                    );
                  })}
                </div>
                {/* adicionar verificação de testada */}
              </FormControl>

              {numUnidades === 0 && formData.distrito !== "" && (
                <>
                  <h5>Endereço do Lote:</h5>

                  <RowContainer>
                    <InputComponent
                      label='Logradouro Atual'
                      id='endereco.logradouro'
                      value={formData.endereco.logradouro}
                      onChange={handleInputChange}
                      readOnly
                      required
                    />
                    <SelectComponent
                      label='Novo logradouro para cadastro'
                      id='select'
                      value={formData.valueSelectTestada}
                      onChange={(e: any) =>
                        handleSelectChange("valueSelectTestada", e.target.value)
                      }
                      options={adversidade.testadas.map((testada, index) => ({
                        value: testada.logradouronome,
                        label: testada.logradouronome,
                      }))}
                      errors={null}
                    />
                    <InputComponent
                      label='Número'
                      id='endereco.numero'
                      value={formData.endereco.numero}
                      onChange={handleInputChange}
                    />
                  </RowContainer>

                  <RowContainer>
                    <InputComponent
                      label='Complemento'
                      id='endereco.complemento'
                      value={formData.endereco.complemento}
                      onChange={handleInputChange}
                    />
                    <InputComponent
                      label='Bairro'
                      id='endereco.bairro'
                      value={formData.endereco.bairro}
                      onChange={handleInputChange}
                    />
                    <InputComponent
                      label='Apartamento'
                      id='endereco.apartamento'
                      value={formData.endereco.apartamento}
                      onChange={handleInputChange}
                    />
                    <InputComponent
                      label='Loteamento'
                      id='endereco.loteamento'
                      value={formData.endereco.loteamento}
                      onChange={handleInputChange}
                    />
                  </RowContainer>
                </>
              )}
              <RowContainer>
                <InputComponent
                  label='Nº de Unidade(s)'
                  id='Nº de Unidade(s)'
                  value={numUnidades}
                  onChange={(e: any) => {
                    const filteredValue = e.target.value.replace(/\D/g, "");
                    const newValue =
                      filteredValue.length > 0 ? parseInt(filteredValue) : 0;
                    setNumUnidades(newValue);
                  }}
                  // type='number'
                  // min={0}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 10,
                    width: "100%",
                    height: 60,
                  }}
                >
                  <label
                    htmlFor='codigo'
                    style={{ display: "block", marginBottom: 5 }}
                  >
                    Clique aqui para continuar:
                  </label>
                  <Button
                    sx={{
                      height: "28px",
                      padding: "5px",
                      minWidth: "100px",
                      verticalAlign: "middle",
                    }}
                    onClick={handleContinue}
                  >
                    Continuar
                  </Button>
                </div>
              </RowContainer>
            </div>
          </Box>
        </form>

        <InputDados />

        <ButtonContainer>
          <Button onClick={handleSave}>
            <FontAwesomeIcon icon={faFloppyDisk} />
            Salvar
          </Button>
        </ButtonContainer>
      </Card>
    </Container>
  );
}
