import React, { useState, useEffect, useContext } from 'react';
import { Box, TextField, MenuItem, Typography, Button, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { ArrowLeftRounded, ArrowRightRounded } from '@material-ui/icons';
import { ContextModalLote } from '../ContextModalLote/ContextModalLote';
import { styled } from '@mui/material/styles';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-root': {
        marginRight: 0,
    },
    '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
        fontSize: 12,
        marginBottom: 0,
    }
}));

const Pagination = ({ unidadesNumber, functionPagination, paginationActive }) => {
    const pagesArray = Array.from({ length: unidadesNumber }, (_, index) => index + 1);

    return (
        <>
            {pagesArray.map((item, index) => (
                <Button
                    key={index}
                    onClick={() => { functionPagination(index) }}
                    variant={(paginationActive === index) ? "contained" : "outlined"}
                    sx={{ width: '1rem', padding: '0.2rem 1rem', minWidth: 0 }}
                >
                    {item}
                </Button>
            ))}
        </>
    );
};

const TabUnidadeImobiliaria = ({ unidades, onUpdate, unidadesDataAtual }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [unidadesData, setUnidadesData] = useState([]);

    useEffect(() => {
        if (unidades && unidades.length > 0) {
            setUnidadesData(unidades.map((unidade, index) => ({
                id: unidade.id || '',
                utilizacao: unidadesDataAtual?.[index]?.utilizacao || '',
                tipo: unidadesDataAtual?.[index]?.tipo || '',
                alinhamento: unidadesDataAtual?.[index]?.alinhamento || '',
                posicao: unidadesDataAtual?.[index]?.posicao || '',
                classificacao: unidadesDataAtual?.[index]?.classificacao || '',
                pisoExterno: unidadesDataAtual?.[index]?.pisoExterno || '',
                pontoDeInteresse: unidadesDataAtual?.[index]?.pontoDeInteresse || unidade.pontoDeInteresse || false,
                tipoInteresse: unidadesDataAtual?.[index]?.tipoInteresse || '',
                nomeInteresse: unidadesDataAtual?.[index]?.nomeInteresse || ''
            })));
        }
    }, [unidades, unidadesDataAtual]);

    useEffect(() => {
        if (onUpdate) {
            onUpdate(unidadesData);
        }
    }, [unidadesData, onUpdate]);

    const handleChange = (field, value) => {
        setUnidadesData(prevState => {
            const newUnidades = [...prevState];
            newUnidades[currentIndex] = {
                ...newUnidades[currentIndex],
                [field]: value
            };
            return newUnidades;
        });
    };

    const handlePagination = (index) => {
        setCurrentIndex(index);
    };

    if (!unidades || unidades.length === 0) {
        return <Typography>Nenhuma unidade disponível</Typography>;
    }

    const currentUnidade = unidades[currentIndex];
    const currentUnidadeData = unidadesData[currentIndex];

    const options = {
        utilizacao: [
            { label: "Residencial", value: "RESIDENCIAL" },
            { label: "Comercial", value: "COMERCIAL" },
            { label: "Serviços", value: "SERVICOS" },
            { label: "Industrial", value: "INDUSTRIAL" },
            { label: "Religioso", value: "RELIGIOSO" },
            { label: "Pública", value: "PUBLICA" },
            { label: "Mista", value: "MISTA" },
            { label: "Agropecuária", value: "AGROPECUARIA" },
            { label: "Terreno sem Uso", value: "TERRENO_SEM_USO" },
            { label: "Outros", value: "OUTROS" }
        ],
        tipo: [
            { label: "Casa/Sobrando", value: "Casa/Sobrando" },
            { label: "Apartamento", value: "Apartamento" },
            { label: "Sala/Loja", value: "Sala/Loja" },
            { label: "Fábrica/Indústria", value: "Fábrica/Indústria" },
            { label: "Galpão", value: "Galpão" },
            { label: "Telheiros", value: "Telheiros" },
            { label: "Misto", value: "Misto" },
            { label: "Outros", value: "Outros" }
        ],
        alinhamento: [
            { label: "Frente Alinhada", value: "FRENTE_ALINHADA" },
            { label: "Frente Recuada", value: "FRENTE_RECUADA" },
            { label: "Fundos", value: "FUNDOS" }
        ],
        posicao: [
            { label: "Fundos", value: "FUNDOS" },
            { label: "Casa/Sobrando", value: "FUNDOS" },
            { label: "Apartamento", value: "FUNDOS" },
            { label: "Sala/Loja", value: "FUNDOS" },
            { label: "Fábrica/Indústria", value: "FUNDOS" },
            { label: "Galpão", value: "FUNDOS" },
            { label: "Telheiros", value: "FUNDOS" },
            { label: "Misto", value: "FUNDOS" },
            { label: "Outros", value: "FUNDOS" }
        ],
        classificacao: [
            { label: "Rústica", value: "RUSTICA" },
            { label: "Popular", value: "POPULAR" },
            { label: "Média", value: "MEDIA" },
            { label: "Boa", value: "BOA" },
            { label: "Luxo", value: "LUXO" }
        ],
        pisoExterno: [
            { label: "Terra", value: 'TERRA' },
            { label: "Cimento", value: "CIMENTO" },
            { label: "Cerâmica", value: "CERAMICA" },
            { label: "Pedra/Granito", value: "PEDRA/GRANITO" }
        ]
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="row" sx={{ flexBasis: '100%', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <h4>Id/Reduzido: {currentUnidade.id}</h4>
                    <Box display="flex" flexDirection="row" gap={1} sx={{ flexBasis: '100%' }}>
                        <Typography
                            gutterBottom
                            sx={{
                                color: 'text.secondary',
                                fontSize: 12,
                                marginBottom: 0,
                            }}
                        >
                            LoteCod: {currentUnidade.loteCod}
                        </Typography>
                        <Typography
                            gutterBottom
                            sx={{
                                color: 'text.secondary',
                                fontSize: 12,
                                marginBottom: 0,
                            }}
                        >
                            /
                        </Typography>
                        <Typography
                            gutterBottom
                            sx={{
                                color: 'text.secondary',
                                fontSize: 12,
                                marginBottom: 0,
                            }}
                        >
                            UnidadeCod: {currentUnidade.unidadeCod}
                        </Typography>
                    </Box>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                    <StyledFormControlLabel
                        control={
                            <Checkbox
                                checked={currentUnidadeData?.pontoDeInteresse || false}
                                onChange={(event) => handleChange('pontoDeInteresse', event.target.checked)}
                            />
                        }
                        label="Ponto de interesse"
                    />
                </div>
            </Box>

            <Box display="flex" flexDirection="row" gap={2}>
                <TextField
                    select
                    label="Tipo"
                    sx={{ flexBasis: '100%' }}
                    value={currentUnidadeData?.tipo || ''}
                    onChange={(e) => handleChange('tipo', e.target.value)}
                >
                    {options.tipo.map(option => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Utilização"
                    sx={{ flexBasis: '100%' }}
                    value={currentUnidadeData?.utilizacao || ''}
                    onChange={(e) => handleChange('utilizacao', e.target.value)}
                >
                    {options.utilizacao.map(option => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>

            <Box display="flex" flexDirection="row" gap={2}>
                <TextField
                    select
                    label="Alinhamento"
                    fullWidth
                    value={currentUnidadeData?.alinhamento || ''}
                    onChange={(e) => handleChange('alinhamento', e.target.value)}
                >
                    {options.alinhamento.map(option => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Posição"
                    fullWidth
                    value={currentUnidadeData?.posicao || ''}
                    onChange={(e) => handleChange('posicao', e.target.value)}
                >
                    {options.posicao.map(option => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>

            <Box display="flex" flexDirection="row" gap={2}>
                <TextField
                    select
                    label="Classificação"
                    fullWidth
                    value={currentUnidadeData?.classificacao || ''}
                    onChange={(e) => handleChange('classificacao', e.target.value)}
                >
                    {options.classificacao.map(option => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Piso Externo"
                    fullWidth
                    value={currentUnidadeData?.pisoExterno || ''}
                    onChange={(e) => handleChange('pisoExterno', e.target.value)}
                >
                    {options.pisoExterno.map(option => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>

            {currentUnidadeData?.pontoDeInteresse && (
                <>
                    <Typography
                        gutterBottom
                        sx={{
                            color: 'text.secondary',
                            fontSize: 14,
                            marginBottom: 0,
                        }}
                    >
                        Ponto de Interesse:
                    </Typography>
                    <Box display="flex" flexDirection="row" gap={2}>
                        <TextField
                            label="Tipo"
                            fullWidth
                            value={currentUnidadeData?.tipoInteresse || ''}
                            onChange={(e) => handleChange('tipoInteresse', e.target.value)}
                        />
                        <TextField
                            label="Nome"
                            fullWidth
                            value={currentUnidadeData?.nomeInteresse || ''}
                            onChange={(e) => handleChange('nomeInteresse', e.target.value)}
                        />
                    </Box>
                </>
            )}

            {unidades.length > 1 && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: 280,
                        width: '100%',
                        height: '2rem',
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        justifyContent: 'center',
                        gap: 5
                    }}
                >
                    <IconButton
                        aria-label="voltar"
                        size="small"
                        onClick={() => {
                            if (currentIndex > 0) {
                                handlePagination(currentIndex - 1);
                            }
                        }}
                    >
                        <ArrowLeftRounded fontSize="small" />
                    </IconButton>
                    <Pagination
                        unidadesNumber={unidades.length}
                        functionPagination={handlePagination}
                        paginationActive={currentIndex}
                    />
                    <IconButton
                        aria-label="avançar"
                        size="small"
                        onClick={() => {
                            if (currentIndex < unidades.length - 1) {
                                handlePagination(currentIndex + 1);
                            }
                        }}
                    >
                        <ArrowRightRounded fontSize="small" />
                    </IconButton>
                </div>
            )}
        </Box>
    );
};

export default TabUnidadeImobiliaria;
