import React, { createContext, useRef, useContext, useCallback, useState } from 'react';

// Cria o contexto
const InputContext = createContext();

// Cria o provider
export const InputProvider = ({ children }) => {
    const optionsLote = {
        nivel: [{ label: "Ao Nível", value: "Ao Nível" }, { label: "Abaixo", value: "Abaixo" }, { label: "Acima", value: "Acima" }, { label: "Irregular", value: "Irregular" }],
        ocupacao: [
            { label: "Construção Paralizada", value: "Construção Paralizada" },
            { label: "Construido", value: "Construido" },
            { label: "Em Construção", value: "Em Construção" },
            { label: "Não Edificado", value: "Não Edificado" },
            { label: "Reforma/Demolição", value: "Reforma/Demolição" },
            { label: "Ruínas", value: "Ruínas" },
            { label: "Edificado", value: "EDIFICADO" },
            { label: "Vago", value: "VAGO" },
            { label: "Praça", value: "PRACA" },
        ],
        situacaoLote: [
            { label: "Esquina/+ de 1 frente", value: "Esquina/+ de 1 frente" },
            { label: "Meio de Quadra", value: "Meio de Quadra" },
            { label: "Encravado", value: "Encravado" },
            { label: "Gleba", value: "Gleba" },
            { label: "Vila/Condomínio", value: "Vila/Condomínio" },
            { label: "Sítio Rec.", value: "Sítio Rec." },
        ],
        muroPasseio:
            [{ label: "Muro e Passeio", value: "Muro e Passeio" },
            { label: "Só Muro", value: "Só Muro" },
            { label: "Só Passeio", value: "Só Passeio" },
            { label: "Inexistente", value: "Inexistente" }],
        utilizacao: [
            { label: "Residencial", value: "RESIDENCIAL" },
            { label: "Comercial", value: "COMERCIAL" },
            { label: "Serviços", value: "SERVICOS" },
            { label: "Industrial", value: "INDUSTRIAL" },
            { label: "Religioso", value: "RELIGIOSO" },
            { label: "Pública", value: "PUBLICA" },
            { label: "Mista", value: "MISTA" },
            { label: "Agropecuária", value: "AGROPECUARIA" },
            { label: "Terreno sem Uso", value: "TERRENO_SEM_USO" },
            { label: "Outros", value: "OUTROS" }
        ],
    };


    const inputCobertura = useRef({
        cobertura: [
            {
                beiral: '',
                andares: ''
            }
        ]
    })
    const inputValuesRef = useRef({
        utilizacao: '',
        tipo: '',
        alinhamento: '',
        ocupacao: '',
        muroPasseio: '',
        situacaoLote: '',
        nivel: '',
        posicao: '',
        classificacao: '',
        pisoExterno: '',
        cobertura: [],
        unidades: []
    });
    const errorsRef = useRef({});
    const loteDataRef = useRef(null);

    const [, forceUpdate] = useState({});

    const resetInputValues = useCallback(() => {
        inputValuesRef.current = {
            utilizacao: '',
            tipo: '',
            alinhamento: '',
            ocupacao: '',
            muroPasseio: '',
            situacaoLote: '',
            nivel: '',
            posicao: '',
            classificacao: '',
            pisoExterno: '',
            cobertura: [],
            unidades: []
        };
        errorsRef.current = {};
    }, []);

    const validarInputs = useCallback(() => {
        const novosErros = {};

        // Validação das coberturas
        inputValuesRef.current.cobertura.forEach((cobertura, index) => {
            if (!cobertura.andares) {
                novosErros[`${index}-andares`] = "Campo obrigatório";
            }
            if (!cobertura.beiral) {
                novosErros[`${index}-beiral`] = "Campo obrigatório";
            }
        });

        // Validação das unidades
        inputValuesRef.current.unidades.forEach((unidade, index) => {
            ['tipo', 'alinhamento', 'posicao', 'classificacao', 'pisoExterno'].forEach(field => {
                if (!unidade[field]) {
                    novosErros[`unidade-${index}-${field}`] = "Campo obrigatório";
                }
            });
        });

        errorsRef.current = novosErros;
        return Object.keys(novosErros).length === 0;
    }, []);

    const getInputs = useCallback(() => {
        if (validarInputs()) {
            const dadosFormatados = {
                id: loteDataRef.current?.id || 0,
                enderecoId: loteDataRef.current?.enderecoId || 0,
                lote: {
                    id: loteDataRef.current?.lote?.id || 0,
                    areaTerreno: loteDataRef.current?.lote?.areaTerreno || 0,
                    areaTerrenoIsento: loteDataRef.current?.lote?.areaTerrenoIsento || 0,
                    areaConstrucao: loteDataRef.current?.lote?.areaConstrucao || 0,
                    fracaoIdeal: loteDataRef.current?.lote?.fracaoIdeal || 0,
                    topologia: loteDataRef.current?.lote?.topologia || null,
                    pedologia: loteDataRef.current?.lote?.pedologia || null,
                    nivelacao: inputValuesRef.current.nivel || loteDataRef.current?.lote?.nivelacao,
                    tipo: loteDataRef.current?.lote?.tipo || 'Apartamento',
                    segmento: loteDataRef.current?.lote?.segmento || null,
                    lado: loteDataRef.current?.lote?.lado || null,
                    ocupacao: inputValuesRef.current.ocupacao,
                    utilizacao: loteDataRef.current?.lote?.utilizacao || "Comercial",
                    patrimonio: loteDataRef.current?.lote?.patrimonio || "Particular",
                    finalidade: loteDataRef.current?.lote?.finalidade || "Uso Próprio",
                    muro: inputValuesRef.current.muroPasseio.includes("Muro") ? "Sim" : "Não",
                    passeio: inputValuesRef.current.muroPasseio.includes("Passeio") ? "Sim" : "Não",
                    frentes: loteDataRef.current?.lote?.frentes || "Encravado",
                    arvore: loteDataRef.current?.lote?.arvore || "N",
                    formato: loteDataRef.current?.lote?.formato || "Irregular",
                    predial: loteDataRef.current?.lote?.predial || "s",
                    nivel: inputValuesRef.current.nivel,
                    situacaoLote: inputValuesRef.current.situacaoLote,
                    profundidade: loteDataRef.current?.lote?.profundidade || 0,
                    vago: loteDataRef.current?.lote?.vago || null,
                    conservacao: loteDataRef.current?.lote?.conservacao || null,
                },
                unidades: inputValuesRef.current.unidades.map((unidade, index) => ({
                    id: loteDataRef.current?.unidades[index]?.id || 0,
                    areaEdicula: loteDataRef.current?.unidades[index]?.areaEdicula || 0,
                    areaConstrucao: loteDataRef.current?.unidades[index]?.areaConstrucao || 0,
                    tipo: unidade.tipo,
                    conservacao: loteDataRef.current?.unidades[index]?.conservacao || null,
                    posicao: unidade.posicao.toUpperCase(),
                    estrutura: loteDataRef.current?.unidades[index]?.estrutura || "ALVENARIA",
                    cobertura: loteDataRef.current?.unidades[index]?.cobertura || "ESPECIAL",
                    instSanitaria: loteDataRef.current?.unidades[index]?.instSanitaria || "EXTERNA",
                    instEletrica: loteDataRef.current?.unidades[index]?.instEletrica || "APARENTE",
                    categoria: loteDataRef.current?.unidades[index]?.categoria || "APARTAMENTO",
                    piso: unidade.pisoExterno,
                    revestimento: loteDataRef.current?.unidades[index]?.revestimento || "ALVENARIA",
                    fachada: loteDataRef.current?.unidades[index]?.fachada || "ALINHADA",
                    situacao: loteDataRef.current?.unidades[index]?.situacao || "CONJUGADA",
                    forro: loteDataRef.current?.unidades[index]?.forro || "LAJE",
                    parede: loteDataRef.current?.unidades[index]?.parede || "ALVENARIA",
                    esquadrias: loteDataRef.current?.unidades[index]?.esquadrias || "FERRO",
                    acesso: loteDataRef.current?.unidades[index]?.acesso || "NAO_PERMITIDO",
                    classificacao: unidade.classificacao.toUpperCase(),
                    alinhamento: unidade.alinhamento.toUpperCase().replace(/ /g, "_"),
                })),
                coberturas: inputValuesRef.current.cobertura.map(cobertura => ({
                    id: cobertura.id || 0,
                    andares: parseInt(cobertura.andares) || 0,
                    beiral: cobertura.beiral
                })),
                pois: [] || loteDataRef.current?.pois
            };

            console.log("Dados formatados:", dadosFormatados);
            return dadosFormatados;
        } else {
            console.log("Por favor, preencha todos os campos obrigatórios.");
            return false;
        }
    }, [validarInputs]);



    const handleInputChange = useCallback((name, value) => {
        const keys = name.split(/[\.\[\]\s]+/).filter(Boolean);
        let temp = inputValuesRef.current;
        for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            if (!temp[key]) temp[key] = isNaN(keys[i + 1]) ? {} : [];
            temp = temp[key];
        }
        temp[keys[keys.length - 1]] = value;
        forceUpdate({}); // Força uma atualização do componente
    }, []);

    const handleInputChangeCobertura = useCallback((arrayCoberturas) => {
        inputValuesRef.current.cobertura = arrayCoberturas;
        forceUpdate({}); // Força uma atualização do componente
    }, []);

    const handleInputChangeUnidade = useCallback((arrayUnidades) => {
        inputValuesRef.current.unidades = arrayUnidades;
        forceUpdate({}); // Força uma atualização do componente
    }, []);

    const setLoteData = useCallback((data) => {
        loteDataRef.current = data;
    }, []);

    return (
        <InputContext.Provider value={{
            inputValuesRef,
            handleInputChange,
            handleInputChangeCobertura,
            handleInputChangeUnidade,
            optionsLote,
            getInputs,
            errorsRef,
            resetInputValues,
            setLoteData,
            forceUpdate // Adicione forceUpdate ao contexto
        }}>
            {children}
        </InputContext.Provider>
    );
};

// Hook para usar o contexto
export const ContextModalLote = () => {
    return useContext(InputContext);
};
